import {
  type PermissionRule,
  ACCESS_RULES as ACCESS_RULES_FN,
  checkPermissions,
} from "@mono/constants/lib/accessRules";
import { useAuthStore } from "@/stores/auth";
import { useOrganizationStore } from "@/stores/organization";

export const useAuthorization = (orgId?: string) => {
  const authStore = useAuthStore();
  const organizationStore = useOrganizationStore();
  const orgIdNormalized = orgId || organizationStore.orgId;

  const orgPermissions = authStore.getUserOrgPermissions(orgIdNormalized);

  const checkRole = (role: AccessRole, permissions?: typeof orgPermissions) => {
    const normalizedPermissions = permissions || orgPermissions;
    // if undefined, early return false
    if (normalizedPermissions === undefined) {
      return false;
    }

    const roles = {
      isOwner: normalizedPermissions.isOwner === true,
      isManager: normalizedPermissions.isManager === true,
    };
    return roles[role];
  };

  function checkPermission({ rule }: { rule: PermissionRule }) {
    // if undefined, early return false
    if (orgPermissions === undefined) {
      return false;
    }

    return checkPermissions(rule, orgPermissions as any);
  }

  return {
    checkRole,
    checkPermission,
  };
};

export const usePermissionsGuard = ({ rule }: { rule: PermissionRule }) => {
  const authStore = useAuthStore();
  const organizationStore = useOrganizationStore();
  const isAuthorized = computed(() => {
    const orgPermissions = authStore.userRoles.find(
      (role) => role.orgId === organizationStore.orgId
    );
    // if undefined, early return false
    if (orgPermissions === undefined) {
      return false;
    }

    return checkPermissions(rule, orgPermissions as any);
  });

  return { isAuthorized };
};

export const useAccessRules = () => {
  const { t } = useI18n();

  const ACCESS_RULES = computed(() => ACCESS_RULES_FN(t));

  return { ACCESS_RULES };
};
