import revive_payload_client_FroUdQdgp1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OSgrMeDG7g from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ma3U64yJs1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_D03a1RYMgE from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_AWgXAViDMz from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/main/.nuxt/components.plugin.mjs";
import prefetch_client_24Zw0oDY2F from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_MXJazesg5a from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/main/.nuxt/unocss.mjs";
import chunk_reload_client_RB0Q21XWQm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _05_sentry_j5CkoUzNHJ from "/vercel/path0/apps/main/plugins/05.sentry.ts";
import _10_pinia_n1I9P4M6iJ from "/vercel/path0/apps/main/plugins/10.pinia.ts";
import _20_urql_0FGp6HQS3M from "/vercel/path0/apps/main/plugins/20.urql.ts";
import markdowneditor_FagzXM5Mcq from "/vercel/path0/apps/main/plugins/markdowneditor.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/apps/main/plugins/vuetify.ts";
export default [
  revive_payload_client_FroUdQdgp1,
  unhead_OSgrMeDG7g,
  router_Ma3U64yJs1,
  payload_client_D03a1RYMgE,
  check_outdated_build_client_AWgXAViDMz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_24Zw0oDY2F,
  i18n_MXJazesg5a,
  unocss_MzCDxu9LMj,
  chunk_reload_client_RB0Q21XWQm,
  _05_sentry_j5CkoUzNHJ,
  _10_pinia_n1I9P4M6iJ,
  _20_urql_0FGp6HQS3M,
  markdowneditor_FagzXM5Mcq,
  vuetify_7h9QAQEssH
]