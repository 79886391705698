/* eslint-disable camelcase */
import { config, en_US } from "md-editor-v3";

export default defineNuxtPlugin(() => {
  config({
    editorConfig: {
      languageUserDefined: {
        en: en_US,
        ar: {
          titleItem: {
            h1: "عنوان 1",
            h2: "عنوان 2",
            h3: "عنوان 3",
            h4: "عنوان 4",
            h5: "عنوان 5",
            h6: "عنوان 6",
          },
          clipModalTips: {
            buttonUpload: "رفع",
            title: "رفع صورة",
          },
          imgTitleItem: {
            link: "رابط الصورة",
            clip2upload: "رفع مقطع",
            upload: "رفع الصورة",
          },
          footer: {
            markdownTotal: "مجموع الكلمات",
            scrollAuto: "تمكين التمرير التلقائي",
          },
          copyCode: {
            failTips: "فشل النسخ",
            successTips: "تم النسخ",
            text: "نسخ الكود",
          },
          linkModalTips: {
            buttonOK: "موافق",
            descLabel: "الوصف",
            descLabelPlaceHolder: "أدخل وصف الرابط",
            imageTitle: "صورة",
            linkTitle: "رابط",
            urlLabel: "الرابط",
            urlLabelPlaceHolder: "أدخل رابط",
          },
          toolbarTips: {
            bold: "غامق",
            italic: "مائل",
            image: "صورة",
            link: "رابط",
            orderedList: "قائمة مرتبة",
            quote: "اقتباس",
            prettier: "تنسيق",
            underline: "تسطير",
            title: "عنوان",
            preview: "معاينة",
            table: "جدول",
            unorderedList: "قائمة غير مرتبة",
            save: "حفظ",
            strikeThrough: "يتوسطه خط",
            task: "مهمة",
            next: "التالي",
            revoke: "تراجع",
            code: "كود",
            codeRow: "سطر كود",
            sub: "فرعي",
            sup: "فرعي",
          },
        },
      },
    },
  });
});
