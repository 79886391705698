import { defineStore } from "pinia";
import { jwtDecode } from "jwt-decode";
import { useCrisp } from "@mono/ui/src/composables/useCrisp";

import type {
  EmailOtpVerificationArgsSchema,
  EmailRequestVerifyArgsSchema,
  PasswordResetSubmitArgsSchema,
  UserLoginArgsSchema,
  UserRegistrationArgsSchema,
} from "@mono/validation/lib/Auth";
import type { InviteAcceptActionArgsSchema } from "@mono/validation/lib/Organization";
import type { AuthJwtToken } from "@/utils/types/auth";

import { useOrganizationStore } from "@/stores/organization";

const userDetailsAndRolesQuery = graphql(/* GraphQL */ `
  query UserDetailsAndRoles($id: uuid!) {
    details: users_by_pk(id: $id) {
      email
      fullName
      locale
      phone
      phoneCC
      countryId
      country {
        displayName
        displayNameTranslations
      }
      analyticsId
      paymentMethods: userPaymentMethods {
        id
        createdAt
        updatedAt
        userId
        type
        details
      }
    }
    roles: organizationACL {
      orgId
      isOwner
      isManager
      isReadonly
      memberRoles
    }
  }
`);

const userRolesQuery = graphql(/* GraphQL */ `
  query UserRoles {
    organizationACL {
      orgId
      isOwner
      isManager
      isReadonly
      memberRoles
    }
  }
`);

type UserDetails = ResultOf<typeof userDetailsAndRolesQuery>["details"];
type UserRole = Unpacked<ResultOf<typeof userRolesQuery>["organizationACL"]>;

export const useAuthStore = defineStore("auth", () => {
  const { crisp } = useCrisp();
  const { setTokens, authStorage, isLoggedIn } = useAuthStorage();
  const organizationStore = useOrganizationStore();
  const analytics = useAnalyticsMain();

  const USER_ROLES_REFRESH_INTERVAL = 1000 * 60 * 10; // 10 minutes

  const userId = ref("");
  const userDetails = ref<UserDetails>({
    email: "",
    fullName: "",
    locale: "",
    phone: "",
    phoneCC: "",
    countryId: "",
    country: {
      displayName: "",
      displayNameTranslations: {},
    },
    analyticsId: "",
    paymentMethods: [],
  });
  const userRoles = ref<UserRole[]>([]);

  const getUserOrgPermissions = (orgId: string) => {
    return userRoles.value.find((role) => role.orgId === orgId);
  };

  let intervalId: ReturnType<typeof setInterval> | null = null;

  const resetUser = () => {
    crisp.clearSession();
    setTokens({ token: null, refreshToken: null });
    userId.value = "";
    userDetails.value = {
      email: "",
      fullName: "",
      locale: "",
      phone: "",
      phoneCC: "",
      countryId: "",
      country: {
        displayName: "",
        displayNameTranslations: {},
      },
      analyticsId: "",
      paymentMethods: [],
    };
    userRoles.value = [];
  };

  const startUserRoleRefreshTask = () => {
    if (intervalId === null) {
      intervalId = setInterval(async () => {
        if (isLoggedIn.value) {
          await fetchUserRoles();
        }
      }, USER_ROLES_REFRESH_INTERVAL);
    }
  };

  const stopUserRoleRefreshTask = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };

  const fetchUserRoles = async () => {
    const { $urql } = useUrql();

    const result = await $urql.query(userRolesQuery, {}).toPromise();

    if (result.data?.organizationACL) {
      const { organizationACL } = result.data;
      userRoles.value = organizationACL;
      return organizationACL;
    }
  };

  const fetchUser = async () => {
    const { $urql } = useUrql();

    const result = await $urql
      .query(userDetailsAndRolesQuery, { id: userId.value })
      .toPromise();

    if (result.data?.details && result.data?.roles) {
      const { details, roles } = result.data;

      userDetails.value = details;
      userRoles.value = roles;

      // identify logged in user for analytics
      analytics.identifyUser(userDetails.value.analyticsId);
    }
  };

  const login = async ({
    email,
    password,
    rememberMe,
  }: UserLoginArgsSchema) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation Login(
        $email: String!
        $password: String!
        $rememberMe: Boolean
      ) {
        userLoginAction(
          email: $email
          password: $password
          rememberMe: $rememberMe
        ) {
          refreshToken
          token
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          email,
          password,
          rememberMe,
        })
        .toPromise();
      if (data?.userLoginAction) {
        const { token, refreshToken } = data.userLoginAction;
        setTokens({ token, refreshToken });

        return { success: true };
      }
      resetUser();
      return { success: false };
    } catch (error) {
      resetUser();
      return { success: false };
    }
  };

  const register = async ({
    email,
    password,
    locale,
    fullName,
    phone,
    phoneCC,
  }: UserRegistrationArgsSchema) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation Register(
        $email: String!
        $password: String!
        $locale: String!
        $fullName: String!
        $phone: String!
        $phoneCC: String!
      ) {
        userRegisterAction(
          email: $email
          password: $password
          locale: $locale
          fullName: $fullName
          phone: $phone
          phoneCC: $phoneCC
        ) {
          success
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          email,
          password,
          locale,
          fullName,
          phone,
          phoneCC,
        })
        .toPromise();

      if (data?.userRegisterAction) {
        const { success } = data.userRegisterAction;
        return {
          success,
        };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  };

  const logout = () => {
    stopUserRoleRefreshTask();
    resetUser();
    organizationStore.resetOrganization();
    analytics.resetOnLogout();
  };

  const requestVerify = async ({ email }: EmailRequestVerifyArgsSchema) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation RequestVerify($email: String!) {
        emailRequestVerifyAction(email: $email) {
          isRegistered
          isVerified
        }
      }
    `);

    try {
      const { data, error } = await $urql
        .mutation(mutation, { email })
        .toPromise();

      if (data?.emailRequestVerifyAction) {
        const { isVerified, isRegistered } = data.emailRequestVerifyAction;
        return { isVerified, isRegistered, hasError: false, success: true };
      }

      const hasError =
        error?.graphQLErrors !== undefined && error?.graphQLErrors.length !== 0;
      return { hasError, isRegistered: true, success: true };
    } catch (error) {
      return { success: false, isRegistered: true };
    }
  };

  const verify = async ({ linkUUID }: { linkUUID: string }) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation Verify($linkUUID: uuid!) {
        emailLinkVerifyAction(linkUUID: $linkUUID) {
          success
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          linkUUID,
        })
        .toPromise();

      if (data?.emailLinkVerifyAction) {
        const { success } = data.emailLinkVerifyAction;
        const hasVerified = success || false;
        return { hasVerified, success: true };
      }
      return { hasVerified: false, success: true };
    } catch (error) {
      return { hasVerified: false, success: false };
    }
  };

  const otpVerify = async ({
    email,
    otpCode,
  }: EmailOtpVerificationArgsSchema) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation OtpVerify($email: String!, $otpCode: String!) {
        emailOtpVerifyAction(email: $email, otpCode: $otpCode) {
          success
          attemptsRemaining
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          email,
          otpCode,
        })
        .toPromise();

      if (data?.emailOtpVerifyAction) {
        const { success, attemptsRemaining } = data.emailOtpVerifyAction;
        return { hasVerified: success, attemptsRemaining, success: true };
      }
      return { hasVerified: false, success: true };
    } catch (error) {
      return { success: false };
    }
  };

  const requestPasswordReset = async ({ email }: { email: string }) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation RequestPasswordReset($email: String!) {
        passwordResetRequestAction(email: $email) {
          success
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          email,
        })
        .toPromise();

      //! Can't check for success value because the api
      //! returns an empty object
      if (data?.passwordResetRequestAction) {
        return { hasError: false, success: true };
      }
      return { hasError: true, success: true };
    } catch (error) {
      return { success: false };
    }
  };

  const passwordReset = async ({
    linkUUID,
    newPassword,
  }: PasswordResetSubmitArgsSchema) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation PasswordReset($linkUUID: uuid!, $newPassword: String!) {
        passwordResetSubmitAction(
          linkUUID: $linkUUID
          newPassword: $newPassword
        ) {
          success
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          linkUUID,
          newPassword,
        })
        .toPromise();

      if (data?.passwordResetSubmitAction) {
        const { success } = data.passwordResetSubmitAction;
        return { hasVerified: success, success: true };
      }
      return { hasVerified: false, success: true };
    } catch (error) {
      return { success: false };
    }
  };

  const inviteAccept = async ({
    inviteId,
    inviteEmail,
  }: InviteAcceptActionArgsSchema) => {
    const { $urql, graphql } = useUrql();
    const mutation = graphql(/* GraphQL */ `
      mutation InviteAccept($inviteId: uuid!, $inviteEmail: String!) {
        inviteAcceptAction(inviteId: $inviteId, inviteEmail: $inviteEmail) {
          success
          orgId
        }
      }
    `);

    try {
      const { data } = await $urql
        .mutation(mutation, {
          inviteId,
          inviteEmail,
        })
        .toPromise();

      if (data?.inviteAcceptAction) {
        const { orgId, success } = data.inviteAcceptAction;
        return { orgId, hasError: !success, success: true };
      }

      return { hasError: true, success: true };
    } catch (error) {
      return { hasError: true, success: false };
    }
  };

  const init = async () => {
    if (isLoggedIn.value && userId.value === "") {
      const decodedUserDetails = jwtDecode<AuthJwtToken>(
        authStorage.value.token!
      );

      userId.value = decodedUserDetails.userId;
      crisp.setUser({
        email: decodedUserDetails.email,
        crispSignedEmail: decodedUserDetails.crispSignedEmail,
      });
      await fetchUser();

      startUserRoleRefreshTask();
    }
  };

  return {
    userId,
    userDetails,
    userRoles,

    fetchUser,
    fetchUserRoles,
    getUserOrgPermissions,

    init,

    login,
    register,
    logout,
    requestVerify,
    verify,
    otpVerify,
    requestPasswordReset,
    passwordReset,
    inviteAccept,
  };
});
