import { useStorage } from "@vueuse/core";
import type { AuthTokens } from "@/utils/types/auth";

const authStorage = useStorage<AuthTokens>("auth", {
  token: null,
  refreshToken: null,
});

const isLoggedIn = computed(() => !!authStorage.value.token);

export const useAuthStorage = () => {
  // Handel legacy tokens switching for existing logins
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");

  const setTokens = ({ token, refreshToken }: AuthTokens) => {
    authStorage.value.token = token;
    if (refreshToken) {
      authStorage.value.refreshToken = refreshToken;
    }
  };

  const resetTokens = () => {
    authStorage.value.token = undefined;
    authStorage.value.refreshToken = undefined;
  };

  return {
    authStorage,
    isLoggedIn,
    setTokens,
    resetTokens,
  };
};
