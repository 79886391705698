import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth";
import {
  organizationDetailsQuery,
  organizationMembersQuery,
  organizationAccountsQuery,
  organizationReceiptPaymentMethodsQuery,
  reportingDimensionsQuery,
  organizationLockedPeriodsQuery,
  organizationSettingsQuery,
} from "@/composables/useOrganizationQuery";

export type OrganizationDetails = ResultOf<
  typeof organizationDetailsQuery
>["organization"];

export type OrganizationMember = ResultOf<
  typeof organizationMembersQuery
>["organizationMembers"][number];

export type OrganizationAccount = ResultOf<
  typeof organizationAccountsQuery
>["accounts"][number];

export type OrganizationReceiptPaymentMethod = ResultOf<
  typeof organizationReceiptPaymentMethodsQuery
>["receiptPaymentMethods"][number];

export type OrganizationReportingDimension = ResultOf<
  typeof reportingDimensionsQuery
>["reportingDimensions"][number];

export type OrganizationLockedPeriod = ResultOf<
  typeof organizationLockedPeriodsQuery
>["lockedPeriods"][number];

export type OrganizationSalesSettings = ResultOf<
  typeof organizationSettingsQuery
>["salesSettings"];

export type OrganizationPurchasingSettings = ResultOf<
  typeof organizationSettingsQuery
>["purchasingSettings"];

export type OrganizationProductsSettings = ResultOf<
  typeof organizationSettingsQuery
>["productsSettings"];

export type OrganizationFixedAssetsSettings = ResultOf<
  typeof organizationSettingsQuery
>["fixedAssetsSettings"];

export type OrganizationTransactionsSettings = ResultOf<
  typeof organizationSettingsQuery
>["transactionsSettings"];

export const useOrganizationStore = defineStore("organization", () => {
  const authStore = useAuthStore();
  const { setCurrency, clearCurrency } = useCurrency();

  // state
  const orgId = ref("");
  const orgDetails = ref<OrganizationDetails | undefined>(undefined);
  const orgAccounts = ref<OrganizationAccount[]>([]);
  const orgMembers = ref<OrganizationMember[]>([]);
  const orgReceiptPaymentMethods = ref<OrganizationReceiptPaymentMethod[]>([]);
  const orgReportingDimensions = ref<OrganizationReportingDimension[]>([]);
  const orgLockedPeriods = ref<OrganizationLockedPeriod[]>([]);
  const salesSettings = ref<OrganizationSalesSettings | undefined>(undefined);
  const purchasingSettings = ref<OrganizationPurchasingSettings | undefined>(
    undefined
  );
  const productsSettings = ref<OrganizationProductsSettings | undefined>(
    undefined
  );
  const fixedAssetsSettings = ref<OrganizationFixedAssetsSettings | undefined>(
    undefined
  );
  const transactionsSettings = ref<
    OrganizationTransactionsSettings | undefined
  >(undefined);

  const resetOrganization = () => {
    orgId.value = "";
    orgDetails.value = undefined;
    orgAccounts.value = [];
    orgMembers.value = [];
    orgReceiptPaymentMethods.value = [];
    orgReportingDimensions.value = [];
    orgLockedPeriods.value = [];
    salesSettings.value = undefined;
    purchasingSettings.value = undefined;
    productsSettings.value = undefined;
    fixedAssetsSettings.value = undefined;
    transactionsSettings.value = undefined;
    clearCurrency();
  };

  const hydratedAccounts = computed(() => {
    return orgAccounts.value.map((account) => {
      let compositeCode = account.type.toString();
      let accountLevel = 1;
      for (let level = 1; level <= 7; level++) {
        const codeLevelKey = `codeLevel${level}` as keyof typeof account;
        const codeLevel = account[codeLevelKey];

        if (codeLevel) {
          const padLength = level === 1 ? 1 : level === 7 ? 5 : 2;
          compositeCode += codeLevel.toString().padStart(padLength, "0");
        } else {
          accountLevel = level - 1;
          break;
        }
      }
      return { ...account, compositeCode, accountLevel };
    });
  });

  const orgDefaultAccounts = computed(() => ({
    assetsAcquisition:
      fixedAssetsSettings.value?.defaultAssetsAcquisitionAccountId,
    assetsDepreciation: fixedAssetsSettings.value?.defaultDepreciationAccountId,
    assetsAppreciation: fixedAssetsSettings.value?.defaultAppreciationAccountId,
    productsCogs: productsSettings.value?.defaultCogsAccountId,
    productsExpense: productsSettings.value?.defaultExpenseAccountId,
    productsRevenue: productsSettings.value?.defaultRevenueAccountId,
    stocktakeExpense: productsSettings.value?.defaultStocktakeExpenseAccountId,
    stocktakeRevenue: productsSettings.value?.defaultStocktakeRevenueAccountId,
    salesCash: salesSettings.value?.defaultCashAccountId,
    salesReceivables: salesSettings.value?.defaultReceivablesAccountId,
    purchasingCash: purchasingSettings.value?.defaultCashAccountId,
    purchasingPayables: purchasingSettings.value?.defaultPayablesAccountId,
  }));

  const fetchOrganizationDetails = async (organizationId: string) => {
    const { $urql } = useUrql();

    const { data, error } = await $urql
      .query(organizationDetailsQuery, { id: organizationId })
      .toPromise();
    if (data) {
      orgId.value = organizationId;
      orgDetails.value = data.organization;
      setCurrency(data?.organization?.currency);
    }
    return { data, error };
  };

  const fetchOrganizationMembers = async () => {
    const { $urql } = useUrql();

    const { data, error } = await $urql
      .query(organizationMembersQuery, { orgId: orgId.value })
      .toPromise();
    if (data) {
      orgMembers.value = data.organizationMembers;
    }
    return { data, error };
  };

  const fetchOrganizationAccounts = async () => {
    const { $urql } = useUrql();

    const { data, error } = await $urql
      .query(organizationAccountsQuery, { orgId: orgId.value })
      .toPromise();
    if (data) {
      const accounts = data.accounts || [];
      orgAccounts.value = [...accounts];
    }
    return { data, error };
  };

  const fetchOrganizationReceiptPaymentMethods = async () => {
    const { $urql } = useUrql();

    const { data, error } = await $urql
      .query(organizationReceiptPaymentMethodsQuery, { orgId: orgId.value })
      .toPromise();

    if (data) {
      orgReceiptPaymentMethods.value = data?.receiptPaymentMethods;
    }

    return { data, error };
  };

  const fetchOrganizationLockedPeriods = async () => {
    const { $urql } = useUrql();

    const { data, error } = await $urql
      .query(organizationLockedPeriodsQuery, { orgId: orgId.value })
      .toPromise();

    if (data) {
      orgLockedPeriods.value = data?.lockedPeriods;
    }

    return { data, error };
  };

  const fetchOrganizationTransactionsSettings = async () => {
    const { $urql, graphql } = useUrql();
    const query = graphql(/* GraphQL */ `
      query OrganizationTransactionsSettings($orgId: uuid!) {
        transactionsSettings: transactionsSettings_by_pk(orgId: $orgId) {
          updatedAt
          backDatingRange
          forwardDatingRange
          isConfirmedLock
          openingBalanceAccountId
          journalsReferencePrefix
          journalsReferenceCounter
          journalsReferenceIsYearPrefixed
          receiptsReferencePrefix
          receiptsReferenceCounter
          receiptsReferenceIsYearPrefixed
          financialYearOffset
        }
      }
    `);

    const { data, error } = await $urql
      .query(query, { orgId: orgId.value })
      .toPromise();

    if (error ?? !data?.transactionsSettings) {
      throw error ?? new Error("No transactions settings found");
    }

    transactionsSettings.value = data.transactionsSettings;
  };

  const fetchOrganizationMetadata = async () => {
    const { $urql, graphql } = useUrql();

    const query = graphql(/* GraphQL */ `
      query OrganizationMetadata($orgId: uuid!) {
        accounts(
          order_by: [
            { type: asc }
            { codeLevel1: asc }
            { codeLevel2: asc }
            { codeLevel3: asc }
            { codeLevel4: asc }
            { codeLevel5: asc }
            { codeLevel6: asc }
            { codeLevel7: asc }
          ]
          where: { orgId: { _eq: $orgId } }
        ) {
          id
          orgId
          type
          isActive
          category
          name
          nameTranslations
          description
          descriptionTranslations
          codeLevel1
          codeLevel2
          codeLevel3
          codeLevel4
          codeLevel5
          codeLevel6
          codeLevel7
          createdAt
          updatedAt
        }
        organizationMembers(
          where: { orgId: { _eq: $orgId }, acceptedAt: { _is_null: false } }
          order_by: { createdAt: asc }
        ) {
          id
          createdAt
          updatedAt
          deletedAt
          acceptedAt
          orgId
          name
          userId
          inviteEmail
          notes
          isManager
          isSuspended
          isReadonly
          organizationMemberRoles {
            roleId
            memberId
            organizationRole {
              id
              name
              description
              accessRules
            }
          }
        }
        receiptPaymentMethods(
          where: { orgId: { _eq: $orgId } }
          order_by: { createdAt: asc }
        ) {
          id
          createdAt
          updatedAt
          name
          nameTranslations
          displayName
          displayNameTranslations
          description
          descriptionTranslations
          isEnabled
          isPosEnabled
          receiptType
          hasCost
          costFixed
          costName
          costNameTranslations
          costPercent
          expenseAccountId
          cashAccountId
          taxId
          taxType {
            id
            accountId
            name
            nameTranslations
            displayName
            displayNameTranslations
            taxPercent
            isDeductible
          }
        }
        reportingDimensions(where: { orgId: { _eq: $orgId } }) {
          id
          createdAt
          updatedAt
          name
          nameTranslations
          description
          descriptionTranslations
          isArchived
        }
        lockedPeriods(where: { orgId: { _eq: $orgId } }) {
          id
          createdAt
          orgId
          startDate
          endDate
          isActive
        }
        salesSettings: salesSettings_by_pk(orgId: $orgId) {
          defaultCashAccountId
          defaultReceivablesAccountId
          invoicesPrintNotesDefault
          invoicesReferenceCounter
          invoicesReferenceIsYearPrefixed
          invoicesReferencePrefix
          receiptsReferenceCounter
          receiptsReferenceIsYearPrefixed
          receiptsReferencePrefix
          salesQuotationsReferenceCounter
          salesQuotationsReferenceIsYearPrefixed
          salesQuotationsReferencePrefix
          creditNotesReferenceCounter
          creditNotesReferenceIsYearPrefixed
          creditNotesReferencePrefix
          defaultIsCash
          updatedAt
          enableInvoicePaymentMethods
        }
        purchasingSettings: purchasingSettings_by_pk(orgId: $orgId) {
          defaultCashAccountId
          defaultPayablesAccountId
          invoicesReferenceCounter
          invoicesReferenceIsYearPrefixed
          invoicesReferencePrefix
          receiptsReferenceCounter
          receiptsReferenceIsYearPrefixed
          receiptsReferencePrefix
          purchaseOrdersReferenceCounter
          purchaseOrdersReferenceIsYearPrefixed
          purchaseOrdersReferencePrefix
          debitNotesReferenceCounter
          debitNotesReferenceIsYearPrefixed
          debitNotesReferencePrefix
          updatedAt
          enableInvoicePaymentMethods
        }
        productsSettings: productsSettings_by_pk(orgId: $orgId) {
          updatedAt
          defaultCategoryId
          defaultCogsAccountId
          defaultExpenseAccountId
          defaultRevenueAccountId
          defaultStocktakeExpenseAccountId
          defaultStocktakeRevenueAccountId
          defaultStoreId
          singleStoreMode
          stocktakesReferenceCounter
          stocktakesReferenceIsYearPrefixed
          stocktakesReferencePrefix
          transfersReferenceCounter
          transfersReferenceIsYearPrefixed
          transfersReferencePrefix
          defaultTaxIsIncluded
        }
        fixedAssetsSettings: fixedAssetsSettings_by_pk(orgId: $orgId) {
          updatedAt
          defaultAppreciationAccountId
          defaultDepreciationAccountId
          defaultAssetsAcquisitionAccountId
          defaultDepreciationLifetime
        }
        transactionsSettings: transactionsSettings_by_pk(orgId: $orgId) {
          updatedAt
          backDatingRange
          forwardDatingRange
          isConfirmedLock
          openingBalanceAccountId
          journalsReferencePrefix
          journalsReferenceCounter
          journalsReferenceIsYearPrefixed
          receiptsReferencePrefix
          receiptsReferenceCounter
          receiptsReferenceIsYearPrefixed
          financialYearOffset
        }
      }
    `);

    const { data } = await $urql
      .query(query, { orgId: orgId.value })
      .toPromise();
    if (data) {
      orgAccounts.value = data?.accounts;
      orgMembers.value = data?.organizationMembers;
      orgReceiptPaymentMethods.value = data?.receiptPaymentMethods;
      orgReportingDimensions.value = data?.reportingDimensions;
      orgLockedPeriods.value = data?.lockedPeriods;
      salesSettings.value = data?.salesSettings;
      purchasingSettings.value = data?.purchasingSettings;
      productsSettings.value = data?.productsSettings;
      fixedAssetsSettings.value = data?.fixedAssetsSettings;
      transactionsSettings.value = data?.transactionsSettings;
    }
  };

  const fetchReportingDimensions = async () => {
    const { $urql } = useUrql();

    const { data } = await $urql
      .query(reportingDimensionsQuery, { orgId: orgId.value })
      .toPromise();
    if (data) {
      orgReportingDimensions.value = data?.reportingDimensions;
    }
  };

  const fetchOrganizationSettings = async () => {
    const { $urql } = useUrql();

    const { data } = await $urql
      .query(organizationSettingsQuery, { orgId: orgId.value })
      .toPromise();
    if (data) {
      salesSettings.value = data?.salesSettings;
      purchasingSettings.value = data?.purchasingSettings;
      productsSettings.value = data?.productsSettings;
      fixedAssetsSettings.value = data?.fixedAssetsSettings;
      transactionsSettings.value = data?.transactionsSettings;
    }
  };

  const init = async (
    orgId: string
  ): Promise<{
    isInactiveOrg: boolean | null;
    isNetworkError: boolean;
    onboardingRedirect: boolean | null;
  }> => {
    resetOrganization();
    const { data, error } = await fetchOrganizationDetails(orgId);

    if (data?.organization) {
      const isActiveOrg = data.organization?.isActive === true;

      if (isActiveOrg) {
        await Promise.all([
          authStore.fetchUserRoles(),
          fetchOrganizationMetadata(),
        ]);

        const onboardingRedirect = orgAccounts.value.length === 0;

        return {
          isInactiveOrg: !isActiveOrg,
          isNetworkError: false,
          onboardingRedirect,
        };
      }

      return {
        isInactiveOrg: !isActiveOrg,
        isNetworkError: false,
        onboardingRedirect: null,
      };
    }

    if (error?.networkError) {
      return {
        isInactiveOrg: null,
        isNetworkError: true,
        onboardingRedirect: null,
      };
    }

    return {
      isInactiveOrg: null,
      isNetworkError: false,
      onboardingRedirect: null,
    };
  };

  return {
    // state
    orgId,
    orgDetails,
    orgAccounts,
    orgMembers,
    orgReceiptPaymentMethods,
    orgReportingDimensions,
    orgLockedPeriods,
    salesSettings,
    purchasingSettings,
    productsSettings,
    fixedAssetsSettings,
    transactionsSettings,
    // computed
    hydratedAccounts,
    orgDefaultAccounts,

    resetOrganization,
    fetchOrganizationAccounts,
    fetchReportingDimensions,
    fetchOrganizationSettings,
    fetchOrganizationDetails,
    fetchOrganizationMembers,
    fetchOrganizationReceiptPaymentMethods,
    fetchOrganizationLockedPeriods,
    fetchOrganizationTransactionsSettings,

    init,
  };
});
